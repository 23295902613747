<script>
import NavBar from "@/components/nav-bar";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";
import {mapGetters } from 'vuex';

  export default {
    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },
    computed: {
      ...mapGetters({
        userData: "auth/user",
      })
    },
    methods: {
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
      },
      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document
              .querySelector("#navbar-nav")
              .querySelector('[href="' + ele + '"]');

            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add(
                  "active"
                );
                parentCollapseDiv.parentElement.children[0].setAttribute(
                  "aria-expanded",
                  "true"
                );
                if (
                  parentCollapseDiv.parentElement.closest(
                    ".collapse.menu-dropdown"
                  )
                ) {
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                  if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                  )
                    parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
                }
              }
            }
          }
        }, 1000);
      },
    },
    components: {
      NavBar,
      RightBar,
      Footer
    },
  };
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/orders" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/triq-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/triq-logo.png" alt="" height="40" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/orders" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/triq-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/triq-logo.png" alt="" height="40" />
            </span>
          </router-link>

          
          <button type="button" class="
              btn btn-sm
              p-0
              fs-20
              header-item
              float-end
              btn-vertical-sm-hover
            " id="vertical-hover">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>
        <div id="scrollbar">
          <div class="container-fluid">
            <ul class="navbar-nav h-100" id="navbar-nav">
              <li class="menu-title">
                <span data-key="t-menu"> {{ $t("t-menu") }}</span>
              </li>

              <li class="nav-item" v-if="userData && userData.role_id == 3">
                <router-link to="/order" class="nav-link" data-key="t-order">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-order">Place Order</span>
                </router-link>
              </li>
              
              <li class="nav-item" v-if="userData && (userData.role_id == 1 || userData.role_id == 2)">
                <router-link to="/admin/users" class="nav-link" data-key="t-calendar">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-dashboards">{{ $t("t-users") }}</span>
                </router-link>
              </li>

                 
              <li class="nav-item" v-if="userData && (userData.role_id == 1 || userData.role_id == 2)">
                <router-link to="/admin/skills" class="nav-link" data-key="t-calendar">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-dashboards">Skills</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="userData && (userData.role_id == 1 || userData.role_id == 2)">
                <router-link to="/admin/industry" class="nav-link" data-key="t-calendar">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-dashboards">Industries</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="userData && (userData.role_id == 1 || userData.role_id == 2)">
                <router-link to="/admin/service/category" class="nav-link" data-key="t-calendar">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-dashboards">Service Category</span>
                </router-link>
              </li>

              <!-- <li class="nav-item" v-if="userData && userData.role_id == 3">
                <router-link to="/services" class="nav-link" data-key="t-services">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-services">{{ $t("t-services") }}</span>
                </router-link>
              </li> -->

              <li class="nav-item" v-if="userData && (userData.role_id == 1 || userData.role_id == 2)">
                <router-link to="/admin/services" class="nav-link" data-key="t-services">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-services">{{ $t("t-services") }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/orders" class="nav-link" data-key="t-orders" v-if="userData && (userData.role_id == 1 || userData.role_id == 2)">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-orders">{{ $t("t-orders") }}</span>
                </router-link>
                <router-link to="/orders" class="nav-link" data-key="t-orders" v-else>
                  <i class="bx bxs-user"></i>
                  <span data-key="t-orders">{{ $t("t-orders") }}</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="userData && (userData.role_id == 4)">
                <router-link to="/availability" class="nav-link" data-key="t-services">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-services">Availability</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="userData && !userData.role_id == 3" >
                <router-link :to="`/client-call`" class="nav-link" data-key="t-services">
                  <i class="bx bxs-user"></i>
                  <span data-key="t-services">Call</span>
                </router-link>
              </li>
            </ul>
          </div>

          <!-- <pre>{{ userData }}</pre> -->
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>
